@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.width_full {
  flex: 1 !important;
  width: 100% !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
SendBird styles
*/

.sendbird-channel-list {
  width: 100% !important;
}

.sendbird-conversation {
  border: none !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
  width: 100% !important;
}

.sendbird-theme--light .sendbird-conversation {
  border-top: none !important;
}

.sendbird-message-content__middle__body-container__created-at, .sendbird-message-status__text {
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  color: #9CA3AF !important;
  font-size: 11.5px !important;
}

.sendbird-label--caption-2 {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600 !important;
  color: #9CA3AF !important;
}

.sendbird-text-message-item-body {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
}

.sendbird-message-content__middle__sender-name {
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  color: #6B7280 !important;
  margin-bottom: 5px !important;
}

.sendbird-message-input--textarea {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  color: black !important;
}

.sendbird-message-input--placeholder {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  color: #9CA3AF !important;
}

.sendbird-notification__text {
  color: white !important;
}

.sendbird-message-input--send {
  top: 12px;
}

.sendbird-fileviewer__header__left {
  display: none !important;
}

.sendbird-fileviewer__header__right {
  width: 100% !important;
}

@media (max-width: 768px) {
  .sendbird-message-input--textarea {
    font-size: 16px !important;
    line-height: 1.05 !important;
    top: 1px;
  }

  .sendbird-message-input--placeholder {
    font-size: 16px !important;
    top: -1px;
  }

  .sendbird-message-input--send {
    top: 13px;
  }

  .sendbird-thumbnail-message-item-body {
    min-width: 250px !important;
    max-width: 50% !important;
  }
}

/*
Other custom styles
 */

.mirrorVideoView {
  /* transform: rotateY(180deg);*/ /* add this property and it will mirror screen sharing! */
}

.link-container a {
  color: #3B82F6;
  text-decoration: none;
}

.link-container a:hover {
  text-decoration: underline;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.markdown-content img {
  width: 100% !important;
}

.markdown-content a {
  color: #3B82F6;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}
